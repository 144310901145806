//Libraries
import React from "react";
//SEO & Layout
import Layout from "../Components/Layout";
import SEO from "../Components/SEO";
//Components
import HeaderImage from "../Components/HeaderImage";
import HomeBodyContent from "../Components/HomeBodyContent";
import AboutUs from "../Components/AboutUs";
import MeetTheTeam from "../Components/MeetTheTeam";

const IndexPage = () => (
  <Layout>
    <SEO
      keywords={[
        "cellular",
        "tower",
        "mapping",
        "western technical",
        "westerntechnical",
        "Telecommunication Company",
        "Public & Government Service",
        "JuAnne Codding",
        "Codding"
      ]}
      title={"Home"}
    />
    <HeaderImage />
    <HomeBodyContent />
    <AboutUs />
    <MeetTheTeam />
  </Layout>
);

export default IndexPage;
