/* Library Imports */
import React from "react";
import { Grid } from "semantic-ui-react";
import Slider from "react-animated-slider";

/* Style Imports */
import "react-animated-slider/build/horizontal.css";
import "../../CSS/headerImage.css";

/* Image Imports */
import one from "../../Images/IMG_0006.jpg";
import two from "../../Images/IMG_3547.jpg";
import three from "../../Images/Photo_0001.jpg";
import four from "../../Images/Foundation Photo 1.jpg";
import five from "../../Images/Foundation Photo.jpg";
import six from "../../Images/Welding Photo.jpg";
import seven from "../../Images/Leaves Fall 2.jpg";
import eight from "../../Images/Leaves Fall.jpg";
import nine from "../../Images/Light Website Photo.jpg";

class HeaderImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      randPic: [
        { src: one },
        { src: two },
        { src: three },
        { src: four },
        { src: five },
        { src: six },
        { src: seven },
        { src: eight },
        { src: nine }
      ]
    };
  }
  render() {
    const { randPic } = this.state;
    return (
      <Grid centered style={{ marginTop: 20 }}>
        <Grid.Row className={"headerImage"}>
          <Slider autoplay={2500} infinite>
            {randPic.map((img, i) => {
              return (
                <div
                  key={`${img.src}-${i}`}
                  style={{
                    height: "100%",
                    backgroundImage: `url('${img.src}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center"
                  }}
                />
              );
            })}
          </Slider>
        </Grid.Row>
      </Grid>
    );
  }
}

export default HeaderImage;

/* <div className={"backgroundCover"}>
<div className={"textOverlayOfFirstThingYouSee"}>
  <Item>
    <Item.Content className={"alignCenterTextOverLay"}>
      <Item.Header>WESTERN TECHNICAL, LLC</Item.Header>
      <Item.Description
        className={"alignCenterTextOverLay"}
      >
        Come Climb with us
      </Item.Description>
    </Item.Content>
  </Item>
</div>
</div>
</div> */
